import { apiService, apiServiceAgent } from '../../services/api.service'
export const NOTIFY_CLIENTS = 'notifyClients'
export const UPDATE_NOTIFICATION_STATUS = 'updateNotificationStatus'
export const NOTIFY_COSSA = 'notifyCossa'
export const UPDATE_NOTIFICATION_COSSA_STATUS = 'updateNotificationCossaStatus'

export default {
  actions: {
    async [NOTIFY_CLIENTS] (state, payload) {
      const body = {}
      if (payload.notifyClientVersion) {
        body.client_version_ids = payload.nonNotifiedClientVersionIds
      } else if (
        payload.notifyAll &&
        this.state.version.nonNotifiedClientVersionIds.length > 0
      ) {
        body.client_version_ids =
          this.state.version.nonNotifiedClientVersionIds
      }

      if (payload.notifyCossaVersion) {
        body.cossa_version_ids = payload.nonNotifiedCossaVersionIds
      } else if (
        payload.notifyAll &&
        this.state.version.nonNotifiedCossaVersionIds.length > 0
      ) {
        body.cossa_version_ids = this.state.version.nonNotifiedCossaVersionIds
      }

      if (payload.notifyInferenceEngineVersion) {
        body.inference_engine_version_ids =
          payload.nonNotifiedInferenceEngineVersionIds
      } else if (
        payload.notifyAll &&
        this.state.version.nonNotifiedInferenceEngineVersionIds.length > 0
      ) {
        body.inference_engine_version_ids =
          this.state.version.nonNotifiedInferenceEngineVersionIds
      }

      if (payload.notifyStreamEngineVersion) {
        body.stream_engine_version_ids =
          payload.nonNotifiedStreamEngineVersionIds
      } else if (
        payload.notifyAll &&
        this.state.version.nonNotifiedStreamEngineVersionIds.length > 0
      ) {
        body.stream_engine_version_ids =
          this.state.version.nonNotifiedStreamEngineVersionIds
      }

      if (
        payload.notifyParameters ||
        (payload.notifyAll &&
          Object.keys(this.state.parameters.changedClientParameters).length > 0)
      ) {
        body.status = 'Notifying'
        body.changed_client_parameters =
          this.state.parameters.changedClientParameters
      }

      if (payload.notifyCossaParameters) {
        body.changed_cossa_parameters = true
        body.status = 'Notifying'
      }

      try {
        await apiServiceAgent.post('notify-clients', { ...body })
        return body
      } catch (error) {
        body.status = 'Error'
        this.dispatch('updateNotificationStatus', (state, { body: body }))
        throw new Error(
          'Error while sending notification request to agent: ' + error.message
        )
      }
    },
    async [UPDATE_NOTIFICATION_STATUS] (state, payload) {
      try {
        await apiService.put('admin/notification-status', { ...payload.body })
      } catch (error) {
        throw new Error(
          'Error while sending notification status to server: ' + error.message
        )
      }
    }
  }
}
