import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import ErrorPage from '../views/ErrorPage.vue'

import store from '../store/index'
import { apiService } from '../../services/api.service'
import { createUrl } from '@/utils/helpers'
import environment from '../../environment'
Vue.use(VueRouter)

const ifAuthenticated = async (to, from, next) => {
  const res: any = await apiService.get('confirm-login/').catch(() => {
    store.dispatch('setAuthenticated', false)
    return false
  })
  if (res) {
    store.dispatch('setAuthenticated', true)
    store.commit('setUser', res.data.user)
  }
  if (store.getters.isAuthenticated) {
    next()
  } else {
    window.location.href = createUrl(
      environment.frontendSecure,
      environment.frontendUrl,
      environment.frontendPort,
      '#/login'
    )
  }
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default new VueRouter({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/error',
      name: 'ErrorPage',
      component: ErrorPage
    },
    {
      path: '/users',
      name: 'users',
      beforeEnter: ifAuthenticated,
      component: () => import('../views/Users.vue')
    },
    {
      path: '/licences',
      name: 'licences',
      beforeEnter: ifAuthenticated,
      component: () => import('../views/Licences.vue')
    },
    {
      path: '/devices',
      name: 'devices',
      beforeEnter: ifAuthenticated,
      component: () => import('../views/Devices.vue')
    },
    {
      path: '/dockers',
      name: 'dockers',
      beforeEnter: ifAuthenticated,
      component: () => import('../views/Dockers.vue')
    },
    {
      path: '/clients',
      name: 'clients',
      beforeEnter: ifAuthenticated,
      component: () => import('../views/Clients.vue')
    },
    {
      path: '/services',
      name: 'services',
      beforeEnter: ifAuthenticated,
      component: () => import('../views/CordatusServices.vue')
    },
    {
      path: '/version-management',
      name: 'versionManagement',
      redirect: '/version-management/client-version',
      beforeEnter: ifAuthenticated,
      component: () =>
        import('../views/VersionManagementPages/VersionManagement.vue'),
      children: [
        {
          path: 'client-version',
          name: 'clientVersion',
          component: () =>
            import('../views/VersionManagementPages/ClientVersion.vue')
        },
        {
          path: 'cossa-version',
          name: 'cossaVersion',
          component: () =>
            import('../views/VersionManagementPages/CossaVersion.vue')
        },
        {
          path: 'stream-engine-version',
          name: 'streamVersion',
          component: () =>
            import('../views/VersionManagementPages/StreamEngineVersion.vue')
        },
        {
          path: 'inference-engine-version',
          name: 'inferenceEngineVersion',
          component: () =>
            import(
              '../views/VersionManagementPages/InferenceEngineVersion.vue'
            )
        }
      ]
    },
    {
      path: '/general',
      name: 'generalSettings',
      beforeEnter: ifAuthenticated,
      redirect: '/general/token-settings',
      component: () => import('../views/settings_cordatus/GeneralSettings.vue'),
      children: [
        {
          path: 'token-settings',
          name: 'tokenSettings',
          component: () =>
            import('../views/settings_cordatus/TokenSettings.vue')
        },
        {
          path: 'parameter-settings',
          name: 'parameterSettings',
          component: () =>
            import('../views/settings_cordatus/ParameterSettings.vue')
        },
        {
          path: 'email-settings',
          name: 'emailSettings',
          component: () =>
            import('../views/settings_cordatus/EmailSettings/EmailSettings.vue')
        }
      ]
    },
    {
      path: '/engines',
      name: 'engines',
      beforeEnter: ifAuthenticated,
      component: () => import('../views/Engines.vue')
    }
  ]
})
