import Swal from 'sweetalert2'
import { apiService } from '../../services/api.service'
import get from 'lodash/get'
import _ from 'lodash'
const GET_CLIENT_VERSION = 'getClientVersion'
const GET_COSSA_VERSION = 'getCossaVersion'
const GET_INFERENCE_RELEASE_LIST = 'getInferenceReleaseList'
const GET_INFERENCE_ENGINE_TAGS = 'getInferenceEngineTags'
const GET_SUPPORTED_OPERATING_SYSTEMS = 'getSupportedOperatingSystem'
const GET_JETPACK_VERSION_LIST = 'getJetpackVersionList'
const SET_NON_NOTIFIED_INFERENCE_ENGINE_VERSION = 'setNonNotifiedInferenceEngineVersion'
const SET_NON_NOTIFIED_CLIENT_VERSION = 'setNonNotifiedClientVersion'
const SET_NON_NOTIFIED_COSSA_VERSION = 'setNonNotifiedCossaVersion'
const REMOVE_NON_NOTIFIED_CLIENT_VERSION = 'removeNonNotifiedClientVersion'
const REMOVE_NON_NOTIFIED_COSSA_VERSION = 'removeNonNotifiedCossaVersion'
const REMOVE_NON_NOTIFIED_INFERENCE_ENGINE_VERSION = 'removeNonNotifiedInferenceEngineVersion'
const SET_NON_NOTIFIED_STREAM_ENGINE_VERSION =
  'setNonNotifiedStreamEngineVersion'
const REMOVE_NON_NOTIFIED_STREAM_ENGINE_VERSION =
  'removeNonNotifiedStreamEngineVersion'
const REMOVE_NON_NOTIFIED_ALL_VERSIONS = 'removeAllNonNotifiedVersions'

const state = {
  nonNotifiedClientVersionIds: [],
  nonNotifiedCossaVersionIds: [],
  nonNotifiedInferenceEngineVersionIds: [],
  nonNotifiedStreamEngineVersionIds: [],
  clientVersionList: [],
  cossaVersionList: [],
  x86CordatusClients: [],
  jetsonCordatusClients: [],
  inferenceReleaseList: [],
  inferenceEngineTags: [],
  supportedOperatingSystems: [],
  jetpackVersionList: [],
  jetpackLoading: false,
  inferenceEngineTagTableLoading: false,
  inferenceReleaseTableLoading: false,
  clientVersionTableLoading: false,
  cossaVersionTableLoading: false,
  selectedInferenceEngine: {
    name: 'No latest tag',
    is_latest: true
  }
}

const getters = {
  nonNotifiedClientVersionIds (state) {
    return state.nonNotifiedClientVersionIds
  },
  nonNotifiedCossaVersionIds (state) {
    return state.nonNotifiedCossaVersionIds
  },
  nonNotifiedStreamEngineVersionIds (state) {
    return state.nonNotifiedStreamEngineVersionIds
  },
  nonNotifiedInferenceEngineVersionIds (state) {
    return state.nonNotifiedInferenceEngineVersionIds
  },
  clientVersionList (state) {
    return state.clientVersionList
  },
  cossaVersionList (state) {
    return state.cossaVersionList
  },
  x86CordatusClients (state) {
    return state.x86CordatusClients
  },
  jetsonCordatusClients (state) {
    return state.jetsonCordatusClients
  },
  clientVersionTableLoading (state) {
    return state.clientVersionTableLoading
  },
  cossaVersionTableLoading (state) {
    return state.cossaVersionTableLoading
  },
  inferenceReleaseList (state) {
    return state.inferenceReleaseList
  },
  inferenceReleaseTableLoading (state) {
    return state.inferenceReleaseTableLoading
  },
  inferenceEngineTags (state) {
    return state.inferenceEngineTags
  },
  inferenceEngineTagTableLoading (state) {
    return state.inferenceEngineTagTableLoading
  },
  selectedInferenceEngine (state) {
    return state.selectedInferenceEngine
  },
  supportedOperatingSystems (state) {
    return state.supportedOperatingSystems
  },
  jetpackVersionList (state) {
    return state.jetpackVersionList
  },
  jetpackLoading (state) {
    return state.jetpackLoading
  }
}

const actions = {
  [REMOVE_NON_NOTIFIED_ALL_VERSIONS] (state) {
    state.commit(REMOVE_NON_NOTIFIED_ALL_VERSIONS)
  },
  [REMOVE_NON_NOTIFIED_CLIENT_VERSION] (state, payload) {
    state.commit(REMOVE_NON_NOTIFIED_CLIENT_VERSION, payload)
  },
  [REMOVE_NON_NOTIFIED_COSSA_VERSION] (state, payload) {
    state.commit(REMOVE_NON_NOTIFIED_COSSA_VERSION, payload)
  },
  [REMOVE_NON_NOTIFIED_STREAM_ENGINE_VERSION] (state, payload) {
    state.commit(REMOVE_NON_NOTIFIED_STREAM_ENGINE_VERSION, payload)
  },
  [SET_NON_NOTIFIED_INFERENCE_ENGINE_VERSION] (state, payload) {
    state.commit(SET_NON_NOTIFIED_INFERENCE_ENGINE_VERSION, payload)
  },
  [SET_NON_NOTIFIED_CLIENT_VERSION] (state, payload) {
    state.commit(SET_NON_NOTIFIED_CLIENT_VERSION, payload)
  },
  [SET_NON_NOTIFIED_COSSA_VERSION] (state, payload) {
    state.commit(SET_NON_NOTIFIED_COSSA_VERSION, payload)
  },
  [SET_NON_NOTIFIED_STREAM_ENGINE_VERSION] (state, payload) {
    state.commit(SET_NON_NOTIFIED_STREAM_ENGINE_VERSION, payload)
  },
  [GET_CLIENT_VERSION] (state, data) {
    state.commit(GET_CLIENT_VERSION, data)
  },
  [GET_COSSA_VERSION] (state, data) {
    state.commit(GET_COSSA_VERSION, data)
  },
  [GET_INFERENCE_RELEASE_LIST] (state, data) {
    state.commit(GET_INFERENCE_RELEASE_LIST, data)
  },
  [GET_INFERENCE_ENGINE_TAGS] (state, data) {
    state.commit(GET_INFERENCE_ENGINE_TAGS, data)
  },
  [GET_SUPPORTED_OPERATING_SYSTEMS] (state, data) {
    state.commit(GET_SUPPORTED_OPERATING_SYSTEMS, data)
  },
  [GET_JETPACK_VERSION_LIST] (state, data) {
    state.commit(GET_JETPACK_VERSION_LIST, data)
  }
}

const mutations = {
  [REMOVE_NON_NOTIFIED_ALL_VERSIONS] (state) {
    state.nonNotifiedClientVersionIds = []
    state.nonNotifiedStreamEngineVersionIds = []
  },
  [REMOVE_NON_NOTIFIED_STREAM_ENGINE_VERSION] (state, payload) {
    state.nonNotifiedStreamEngineVersionIds.find((id, index) => {
      if (id === payload) {
        state.nonNotifiedStreamEngineVersionIds.splice(index, 1)
      }
    })
  },
  [REMOVE_NON_NOTIFIED_CLIENT_VERSION] (state, payload) {
    state.nonNotifiedClientVersionIds.find((id, index) => {
      if (id === payload) {
        state.nonNotifiedClientVersionIds.splice(index, 1)
      }
    })
  },
  [REMOVE_NON_NOTIFIED_COSSA_VERSION] (state, payload) {
    state.nonNotifiedCossaVersionIds.find((id, index) => {
      if (id === payload) {
        state.nonNotifiedCossaVersionIds.splice(index, 1)
      }
    })
  },
  [REMOVE_NON_NOTIFIED_INFERENCE_ENGINE_VERSION] (state, payload) {
    state.nonNotifiedInferenceEngineVersionIds.find((id, index) => {
      if (id === payload) {
        state.nonNotifiedInferenceEngineVersionIds.splice(index, 1)
      }
    })
  },
  [SET_NON_NOTIFIED_CLIENT_VERSION] (state, payload) {
    state.nonNotifiedClientVersionIds.push(payload)
  },
  [SET_NON_NOTIFIED_COSSA_VERSION] (state, payload) {
    state.nonNotifiedCossaVersionIds.push(payload)
  },
  [SET_NON_NOTIFIED_STREAM_ENGINE_VERSION] (state, payload) {
    state.nonNotifiedStreamEngineVersionIds.push(payload)
  },
  [SET_NON_NOTIFIED_INFERENCE_ENGINE_VERSION] (state, payload) {
    state.nonNotifiedInferenceEngineVersionIds.push(payload)
  },
  [GET_CLIENT_VERSION] (state) {
    apiService
      .get('admin/client_version')
      .then((res) => {
        state.clientVersionList = res.data.data
        state.x86CordatusClients = _.filter(
          state.clientVersionList,
          (obj) => obj.arch === 'x64'
        )
        state.jetsonCordatusClients = _.filter(
          state.clientVersionList,
          (obj) => obj.arch === 'arm64'
        )
      })
      .catch((err) => {
        state.clientVersionTableLoading = false
        Swal.fire({
          icon: 'error',
          title: 'Error getting client version list',
          text: get(err, 'response.data.message', 'Something went wrong')
        })
      })
      .finally(() => (state.clientVersionTableLoading = false))
  },
  [GET_COSSA_VERSION] (state) {
    state.cossaVersionTableLoading = true
    apiService
      .get('admin/cossa_version')
      .then((res) => {
        state.cossaVersionList = res.data.data
      })
      .catch((err) => {
        state.cossaVersionTableLoading = false
        Swal.fire({
          icon: 'error',
          title: 'Error getting cossa version list',
          text: get(err, 'response.data.message', 'Something went wrong')
        })
      })
      .finally(() => (state.cossaVersionTableLoading = false))
  },
  [GET_INFERENCE_RELEASE_LIST] (state) {
    this.inferenceReleaseTableLoading = true
    apiService
      .get('admin/inference_engine_version')
      .then((res) => {
        state.inferenceReleaseList = res.data.data
        state.selectedInferenceEngine = state.inferenceReleaseList.find(
          (obje) => obje.is_latest === true
        )
        this.commit('getInferenceEngineTags', state.selectedInferenceEngine.id)
      })
      .catch((err) => {
        state.inferenceReleaseTableLoading = false
        Swal.fire({
          icon: 'error',
          title: 'Error getting engine release list',
          text: get(err, 'response.data.message', 'Something went wrong')
        })
      })
      .finally(() => (state.inferenceReleaseTableLoading = false))
  },
  [GET_INFERENCE_ENGINE_TAGS] (state, payload) {
    this.inferenceEngineTagTableLoading = true
    apiService
      .get(`admin/inference_engine_version/tags/${payload}`)
      .then((res) => {
        state.inferenceEngineTags = res.data.data
      })
      .catch((err) => {
        state.inferenceEngineTagTableLoading = false
        Swal.fire({
          icon: 'error',
          title: 'Error getting inference engine tags list',
          text: get(err, 'response.data.message', 'Something went wrong')
        })
      })
      .finally(() => (state.inferenceEngineTagTableLoading = false))
  },
  [GET_SUPPORTED_OPERATING_SYSTEMS] (state) {
    apiService
      .get('admin/supported_operating_system')
      .then((res) => {
        state.supportedOperatingSystems = res.data.data
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Error getting supported os list',
          text: get(err, 'response.data.message', 'Something went wrong')
        })
      })
  },
  [GET_JETPACK_VERSION_LIST] (state) {
    state.jetpackLoading = true
    apiService
      .get('admin/jetpacks')
      .then((res) => {
        state.jetpackVersionList = res.data.data
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Error getting jetpack version list',
          text: get(err, 'response.data.message', 'Something went wrong')
        })
      })
      .finally(() => {
        state.jetpackLoading = false
      })
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
